.StartDimParc {
    width: 163px;
    height: 100px;
    margin-bottom: 30px;
    padding: 5px;
    padding-top: 10px;
    text-decoration: none;
    border: solid 3px transparent;
    background-color: #F6F6F6;
    border-color: #A1A3A4;
    color: #000;

    @media($mq-desktop) {
        width: 320px;
        height: 197px;
        padding: 35px 10px;
    }

    &:first-child {
        margin-right: 20px;
    }

    &.active {
        color: #e62814;
        border-color: #e62814;
    }

    &:hover {
        color: #e62814;
        border-color: #e62814;
    }

    &.disabled {
        color: #808080;
        border-color: #808080;
        cursor: not-allowed;
    }

    &.disabled:hover {
        color: #808080;
        border-color: #808080;
        cursor: not-allowed;
    }

    &--icon {
        text-align: center;
        margin-bottom: 10px;

        svg {
            width: 35px;
            height: 36px;

            @media($mq-desktop) {
                width: 70px;
                height: 72px;
            }
        }

        @media($mq-desktop) {
            margin-bottom: 15px;
        }
    }

    &--descr {
        text-align: center;
        font-size: 8px;
        font-weight: 900;

        @media($mq-desktop) {
            font-size: 18px;
        }
    }
}

.StartDim {
    padding-bottom: 20px !important;

    .SectionHead-mainTitle {
        font-size: 1rem;

        @media($mq-desktop) {
            font-size: 2.2rem;
        }
    }

    .SectionHead-description {
       font-size: 14px;

       @media($mq-desktop) {
        font-size: .85rem;
       }
    }

    .PrimaryButton {
        padding: 17px 45px;
        width: 518px;

        @media($mq-phone) {
            padding: 17px 0;
            width: 100%;
        }
    }

    @media($mq-desktop) {
        padding-bottom: 218px !important;
    }
}